import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Translate } from 'frr-web/lib/translation'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FinancingObject } from '../../types/financing.types'
import { useModuleLoaderContext } from '../../view/context/ModuleLoader.context'
import { useAppConfig } from '../../view/providers/AppConfigData.provider'
import { FinancingSessionStorage } from '../../view/providers/FinancingSession.provider'
import { Notification } from '../../view/providers/Notification.provider'
import { OptIns, processInboundFinancingObj, processOutboundCustomerInfo } from '../api/api.helpers'
import { PatchFinObjParams, QueryKey } from '../api/api.types'
import {
  CreateFinancingSessionParams,
  CreateFinancingSessionResponse,
  createFinancingSession,
} from '../api/fetchapi/financing/createFinancingSession'
import { submitCreditApplication } from '../api/fetchapi/financing/submitCreditApplication'
import { ApiError, BobFinancingFacadeOnboardingService } from '../api/openapi'

/*
 * Module System
 */

export const useSetModuleSystem = () => {
  const { setModuleLoaderContext } = useModuleLoaderContext()

  return (moduleSystem: any) => {
    setModuleLoaderContext({ moduleSystemOverride: moduleSystem })
  }
}

/*
 * Financing Object
 */

export const useClearFinObj = () => {
  const queryClient = useQueryClient()

  return async () => {
    queryClient.clear()
    await queryClient.refetchQueries([QueryKey.FINANCING_OBJECT])
  }
}

type CreateFinancingParams = Omit<CreateFinancingSessionParams, 'appConfig'>

export const useCreateFinancing = () => {
  const { appConfig } = useAppConfig()
  const queryClient = useQueryClient()
  const { setModuleLoaderContext } = useModuleLoaderContext()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (
      params: CreateFinancingParams & {
        onError?: (params: { error: ApiError; meta: CreateFinancingParams; t: TFunction }) => void
      },
    ) => {
      setModuleLoaderContext({
        isApiError: false,
        isModuleSystemLoading: true,
        isSameModuleSystem: false,
      })

      return createFinancingSession({
        appConfig,
        productId: params.productId,
        requestBody: params.requestBody,
      })
    },

    onSuccess: async (res: CreateFinancingSessionResponse, meta) => {
      if (res.session) {
        FinancingSessionStorage.saveSession(res.session)
        await queryClient.refetchQueries([QueryKey.FINANCING_OBJECT])
        // At the moment no need to refetch financing info
        setModuleLoaderContext({
          isModuleSystemLoading: false,
          moduleSystemOverride: null,
        })
      } else {
        setModuleLoaderContext({
          isApiError: true,
          isModuleSystemLoading: false,
        })

        Notification.error(`${t('error.crashTitle')}.${t('error.crashDescription')}`)
      }
    },

    onError: (error: ApiError, meta) => {
      console.error('💥 ERROR WHILE CREATE FINANCING', error)

      setModuleLoaderContext({
        isApiError: true,
        isModuleSystemLoading: false,
      })

      if (meta.onError) {
        meta.onError({ error, meta, t })
      } else {
        Notification.error(`${t('error.crashTitle')}.${t('error.crashDescription')}`)
      }
    },
  })
}

/*
 * Patch Financing Object
 */

const PatchMutation = (params: PatchFinObjParams<FinancingObject>) => {
  return BobFinancingFacadeOnboardingService.patchBobFinancingFacadeOnboardingPatch(params.updates)
}

const useInternalPatchFinObj = (config: {
  onMutate?: (params: PatchFinObjParams<FinancingObject>) => void
  onSuccess?: (finObj: FinancingObject, meta: PatchFinObjParams<FinancingObject>) => void
  onError?: (error: unknown, meta: PatchFinObjParams<FinancingObject>) => void
}) => {
  const queryClient = useQueryClient()

  return useMutation(PatchMutation, {
    onMutate: config.onMutate,
    onSuccess: (finObjBE, meta) => {
      const finObj = processInboundFinancingObj(finObjBE)
      queryClient.setQueryData([QueryKey.FINANCING_OBJECT], finObj)
      config.onSuccess?.(finObj, meta)
    },
    onError: config.onError,
  })
}

export const useHandleInboundFinObj = () => {
  const patchFinObj = useInternalPatchFinObj({})

  // return (finObj: FinancingObject) => {
  //   if (finObj.stateLevelInfo.activeStateLevelBackend === OrderState.DELIVERY_CONFIRMATION) {
  //     patchFinObj.mutate({
  //       updates: [
  //         {
  //           op: 'replace',
  //           path: '/basket/saleInfo/isPartnerDeliveryConfirmed',
  //           value: true,
  //         },
  //       ],
  //     })
  //   }
  // }
}

export const usePatchFinObj = () => {
  // const handleInboundFinObj = useHandleInboundFinObj()

  const { setModuleLoaderContext } = useModuleLoaderContext()
  const { t } = useTranslation()

  const patchFinObj = useInternalPatchFinObj({
    onMutate: (params) => {
      setModuleLoaderContext({
        isApiError: false,
        isModuleSystemLoading: !params.isHideLoading,
        isSameModuleSystem: !!params.isSameModuleSystem,
        moduleSystemOverride: params.nextModuleSystem,
      })
    },
    onSuccess: async (finObj, meta) => {
      if (meta.notifications?.success) {
        Notification.success(`${t(meta.notifications.success)}`)
      }

      setModuleLoaderContext({
        isModuleSystemLoading: false,
        moduleSystemOverride:
          finObj.errorInfo.error.isError || finObj.errorInfo.error.isRejection
            ? null
            : meta.nextModuleSystem || null,
      })

      // handleInboundFinObj(finObj)

      meta.onSuccess?.(finObj, t)
    },
    onError: (_error, meta) => {
      if (meta.notifications?.error) {
        Notification.success(`${t(meta.notifications.error)}`)
      } else {
        Notification.error(`${t('error.crashTitle')}.${t('error.crashDescription')}`)
      }
      setModuleLoaderContext({
        isApiError: true,
        isModuleSystemLoading: false,
      })
    },
  })

  return patchFinObj
}

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient()
  const { setModuleLoaderContext } = useModuleLoaderContext()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (params: {
      finObj: FinancingObject
      optIns: OptIns
      isHideLoading?: boolean
      isSameModuleSystem?: boolean
      onSuccess?: (financing: FinancingObject, t: Translate) => void
      nextModuleSystem?: string
    }) => {
      setModuleLoaderContext({
        isApiError: false,
        isModuleSystemLoading: !params.isHideLoading,
        isSameModuleSystem: !!params.isSameModuleSystem,
        moduleSystemOverride: params.nextModuleSystem,
      })

      return BobFinancingFacadeOnboardingService.patchBobFinancingFacadeOnboardingUpdateCustomer(
        processOutboundCustomerInfo({ financing: params.finObj, optIns: params.optIns }),
      )
    },
    onSuccess: async (finObj, meta) => {
      queryClient.setQueryData([QueryKey.FINANCING_OBJECT], processInboundFinancingObj(finObj))

      setModuleLoaderContext({
        isModuleSystemLoading: false,
        moduleSystemOverride: meta.nextModuleSystem || null,
      })
    },
    onError: (_error, _meta) => {
      setModuleLoaderContext({
        isApiError: true,
        isModuleSystemLoading: false,
      })

      Notification.error(`${t('error.crashTitle')}.${t('error.crashDescription')}`)
    },
  })
}

export const useSubmitCreditApplication = () => {
  const queryClient = useQueryClient()

  const { appConfig } = useAppConfig()
  const { setModuleLoaderContext } = useModuleLoaderContext()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (params: { language?: string; nextModuleSystem?: string }) => {
      setModuleLoaderContext({
        isApiError: false,
        isModuleSystemLoading: false,
        isSameModuleSystem: false,
        moduleSystemOverride: params.nextModuleSystem,
      })

      return submitCreditApplication({ appConfig, language: params.language })
    },
    onSuccess: async (finObj, _meta) => {
      queryClient.setQueryData([QueryKey.FINANCING_OBJECT], processInboundFinancingObj(finObj))

      // DSG: Remove check for Submit into DFS but just wait for the BE to finish and proceed
      // if (finObj.stateLevelInfo.activeStateLevelBackend === OrderState.SUBMIT_INTO_DFS) {
      //   // Automatically move to confirmation after 5s
      //   setTimeout(() => {
      //     setModuleLoaderContext({
      //       isModuleSystemLoading: false,
      //       moduleSystemOverride: 'Confirmation',
      //     })
      //   }, 5 * 1000)
      // } else
      if (finObj.errorInfo.error.isError || finObj.errorInfo.error.isRejection) {
        setModuleLoaderContext({
          isModuleSystemLoading: false,
          moduleSystemOverride: null,
        })
      } else {
        // Automatically move to confirmation after 3s
        setTimeout(
          () =>
            setModuleLoaderContext({
              isModuleSystemLoading: false,
              moduleSystemOverride: 'Confirmation',
            }),
          3 * 1000,
        )
      }
    },
    onError: (_error) => {
      Notification.error(`${t('error.crashTitle')}.${t('error.crashDescription')}`)

      setModuleLoaderContext({
        isApiError: true,
        isModuleSystemLoading: false,
      })
    },
  })
}
