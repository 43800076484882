import React, { ReactNode } from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { MockRedirect, MockType } from '../components/mock/MockRedirect'
import { ApplicationError } from '../scaffold/error/ApplicationError'
import { GeneralError } from '../scaffold/error/GeneralError'
import { SessionError } from '../scaffold/error/SessionError'
import { useUserLanguage } from '../scaffold/translation/useUserLanguage'
import { PartnerSessionProvider } from './PartnerSession.provider'
import { PartnerLoginCallbackProvider } from '../context/PartnerLoginCallback.context'
import { MockPostFinanceLogin } from '../components/mock/MockPostFinanceLogin'

// TODO: --> REMOVE
// const ADLogin = React.lazy(() => import('../scaffold/__authentication/ADLogin'))
// const ADLogout = React.lazy(() => import('../scaffold/__authentication/ADLogout'))

export const RouterProvider = (props: { children: ReactNode }) => {
  const language = useUserLanguage()
  const queryString = window.location.search

  return (
    <Router>
      <Routes>
        {/* <Route path='/login/:lang' element={<ADLogin showAuthenticatedPartnerList />} />
        <Route path='/login' element={<ADLogin showAuthenticatedPartnerList />} />
        <Route path='/logout' element={<ADLogout />} /> */}

        <Route path='/mock/postfinance/login' element={<MockPostFinanceLogin />} />
        <Route path='/mock/success' element={<MockRedirect type={MockType.Success} />} />
        <Route path='/mock/error' element={<MockRedirect type={MockType.Error} />} />
        <Route path='/mock/cancellation' element={<MockRedirect type={MockType.Cancellation} />} />

        <Route path='/:lang/_test/error/general' element={<GeneralError />} />

        <Route
          path='/:lang/_test/error/application'
          element={<ApplicationError>{props.children}</ApplicationError>}
        />
        <Route
          path='/:lang/_test/error/session'
          element={<SessionError>{props.children}</SessionError>}
        />

        <Route path='/partner/login/callback' element={<PartnerLoginCallbackProvider />} />

        <Route
          path='/partner/onboarding'
          element={<Navigate to={`/${language}/partner/onboarding`} replace />}
        />

        <Route
          path='/:lang/partner/onboarding/*'
          element={<PartnerSessionProvider>{props.children}</PartnerSessionProvider>}
        />

        <Route path='/:lang' element={<>{props.children}</>} />

        <Route path='*' element={<Navigate to={`/${language}${queryString}`} replace />} />
      </Routes>
    </Router>
  )
}
