import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FinancingObjectBE } from '../../types/financing.types'
import { FinancingSessionStorage } from '../../view/providers/FinancingSession.provider'
import { processInboundFinancingObj } from '../api/api.helpers'
import { QueryKey } from '../api/api.types'
import { BobFinancingFacadeOnboardingService } from '../api/openapi'
import { PartnerSessionStorage } from '../../view/providers/PartnerSession.provider'
// DSG - Remove once tested
// import { useHandleInboundFinObj } from '../mutations/financingObject.mutations'

/*
 * Get financing object
 */

const getFinancingObject = async (): Promise<FinancingObjectBE | null> => {
  const finObj =
    !!FinancingSessionStorage.getSession() && !PartnerSessionStorage.getSession()
      ? await BobFinancingFacadeOnboardingService.getBobFinancingFacadeOnboardingGet()
      : null

  return finObj
}
export const useGetFinObj = (params: { isPolling: boolean }) => {
  // const handleInboundFinObj = useHandleInboundFinObj()

  return useQuery({
    queryKey: [QueryKey.FINANCING_OBJECT],
    queryFn: getFinancingObject,
    select: (finObj) => (finObj ? processInboundFinancingObj(finObj) : null),
    // onSuccess: (finObj) => {
    //   if (finObj) {
    //     handleInboundFinObj(finObj)
    //   }
    // },
    refetchInterval: params.isPolling ? 5000 : undefined,
    refetchOnWindowFocus: false,
  })
}

export const useRefetchFinObj = (
  params: { includeFinObj: boolean } = {
    includeFinObj: true,
  },
) => {
  const queryClient = useQueryClient()

  return async () => {
    if (params.includeFinObj) {
      await queryClient.refetchQueries([QueryKey.FINANCING_OBJECT])
    }
  }
}
